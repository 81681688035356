import { NextRouteItem } from 'app/core/types/route.type';
import { NextRouteProps } from 'app/core/enums/route.enum';
import { NavItemId } from 'app/shared/enums/navigation.enum';
import { NextPermissionId } from 'app/core/enums/permission.enum';
import { GeneralMaintenanceRoutingConfig } from '@pages/general-maintenance/general-maintenance.route.config';

export class BankSecurityRouteConfig {
  static readonly mainPath = 'banks';
  static readonly listConfig: NextRouteItem = {
    [NextRouteProps.ID]: NavItemId.BANKS,
    label: 'BANK_SECURITY.TITLE',
    [NextRouteProps.PATH]: BankSecurityRouteConfig.mainPath,
    [NextRouteProps.PARENT]: GeneralMaintenanceRoutingConfig.mainRoute,
    [NextRouteProps.PERMISSION]: [NextPermissionId.BANKS]
  };
}
